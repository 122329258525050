html{
    scroll-behavior: smooth;
}
body{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Montserrat", serif;
    color: #fff;
}
p{
    margin-bottom: 0;
}
a{
    text-decoration: none;
}
/* button border #41b9bf */
/* button bg color #1b6975 */

.header__section , .header__section nav.bg-dark {
    background-color: #161616 !important;
}

.header__section .navbar-nav .nav-link.active, .header__section .navbar-nav .nav-link.show {
    color: rgb(255 255 255);
}
.nav-link {
    color: #fff;
}

.header__section ul.navbar-nav.me-auto.mb-2.mb-lg-0 {
    background-color: #121212;
    padding: 0px 40px;
    border: 1px solid hsl(0deg 0% 51.97%);
    border-radius: 50px;
}
a.get__started_btn{
    border: 1px solid #00e5f1;
    color: #00e5f1;
    background-color: #1b6975;
    padding: 10px 15px;
    border-radius: 60px;
    text-decoration: none;
}
.header__section .nav-link:focus, .header__section .nav-link:hover {
    color: #41b9bf;
}
.hero__banner__section {
    background-image: url(../images/home/hero-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.banner__bx__head img {
    width: 20px;
}
.hero__banner__head {
    padding-top: 80px;
    padding-bottom: 50px;
}
.hero__banner__head h1 {
    font-size: 35px;
    text-align: left;
    font-weight: 600;
    margin-bottom: 30px;
}
.hero__banner__head h1 span{
    color: #00e5f1;
}

.hero__banner__head p {
    font-size: 18px;
    text-align: left;
    color: #d5d5d5;
}
.hexagon {
    height: 20px; /* adjust to control the size  */
    aspect-ratio: 1/cos(30deg);
    clip-path: polygon(50% -50%,100% 50%,50% 150%,0 50%);
    background: #41b9bf;
  }

  .banner__bx__head {
    display: flex;
    gap: 15px;
    align-items: center;
    /* margin-bottom: 7px; */
}
.banner__bx {
    border: 1px solid #00e5f1;
    color: #00e5f1;
    background-color: #1b6975ba;
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    width: 90%;
    position: relative;
}
.banner__bx__head h4 {
    font-size: 18px;
    margin-bottom: 0;
}
.banner__bx p {
    margin-bottom: 0;
}
.banner__qube__img__section{
    text-align: center;
}
.banner__qube__img__section img{
    width: 100%;
}
.launch__app__bx{
    background-image: linear-gradient(to right, #284a54, #1a445a, #1d3b5d, #2f305b, #422150);
}
.launch__app__bx .banner__bx__head h4{

}
.launch__app__bx .banner__bx__head h4 {
    color: #0dff00;
}
.token__table__container h3 {
    font-size: 25px;
}
.token__image__bx img.w-100{
    width: 300px !important;
}
/* problem solved with project section css start  */
.project_prob_section {
    background-image: url(../images/home/why-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 90px;
    padding-bottom: 50px;
}
.problem__project__head h2 {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 30px;
}
.problem__project__head p {
    font-size: 18px;
    color: #d5d5d5;
}
.problem__proj__solved__bx {
    background-color: #222222;
    padding: 30px;
    border-radius: 19px;
    margin-bottom: 30px;
    border: 1px solid #d5d5d5;
}
.problem__proj__solved__bx p.icon {
    border: 1px solid #d5d5d5;
    display: inline-block;
    padding: 0px 10px;
    border-radius: 7px;
    font-size: 23px;
    margin-bottom: 15px;
}
.problem__proj__solved__bx h6 {
    font-size: 18px;
}
.problem__proj__solved__bx p {
    font-size: 16px;
}

/* digtal efferctless steps css start  */
.digital__effert__secrion {
    background-image: url(../images/home/digital-effert.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 90px;
    padding-bottom: 50px;
}

.digital__effert__head h3 {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 15px;
    text-align: center;
}
.steps__digital__txt.mt-auto h6 {
    font-size: 18px;
}
.platform__f__bx h4 {
    font-size: 18px;
}
.platform__f__bx p {
    font-size: 16px;
}
.digital__effert__head p {
    font-size: 18px;
    color: #d5d5d5;
    text-align: center;
    margin-bottom: 100px;
}
.steps__digital__img img {
    width: 100%;
}
.steps__bx__container {
    background-color: #222222;
    padding: 30px;
    border-radius: 19px;
    margin-bottom: 30px;
    border: 1px solid #d5d5d5;
    padding: 15px 30px;
    width: 100%;
    position: relative;
    justify-content: center;
}
.steps__digital__txt h6 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}
.steps__digital__img img {
    width: 100%;
    border: 1px solid #d5d5d5;
    border-radius: 19px;
    margin-bottom: 30px;
}
.steps__digital__txt p {
    font-size: 18px;
    text-align: center;
}
.global__market__section {
    background-color: #161616;
    padding-top: 90px;
    padding-bottom: 50px;
}
.footer__section {
    background-color: #161616;
    padding-top: 90px;
    padding-bottom: 50px;
}

.socila__media__icon ul{
    display: inline-flex;
        align-items: center;
        list-style: none;
        gap: 15px;
        padding-bottom: 0;
    margin-bottom: 0;
    padding-left: 0;
}
.socila__media__icon ul li a img {
    width: 24px;
}
.footer__links__bx ul {
    padding-left: 0;
    list-style: none;
}
.footer__links__bx ul li a {
    color: #fff;
}
.footer__links__bx ul li a:hover {
    color: #00e5f1;
}
.platform__features__section{
    background-image: url(../images/home/digital-effert.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 90px;
    padding-bottom: 50px;
}

.platform__f__bx {
    background: #2B2B37;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    padding: 24px;
}
.platform__f__bx:hover {
    background: linear-gradient(256deg, #00e5f1 -4.64%, #1b6975ba 54.33%);
}
.platform__head h3{
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
}
.platform__head p{
    font-size: 18px;
    color: #d5d5d5;
    text-align: center;
    margin-bottom: 50px;
}
.platform__f__bx img {
    margin-bottom: 30px;
}
.platform__f__bx h4 {
    margin-bottom: 15px;
}
.token__copy__bx button img {
    width: 20px;
}
.token__section {
    background-image: linear-gradient(to right, #284a54, #1a445a, #1d3b5d, #2f305b, #422150);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 90px;
    padding-bottom: 50px;
}
.token__table__container {
    padding: 30px;
    border-radius: 15px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.token__text__name {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #6e6e6e;
    margin-bottom: 20px;
    padding-bottom: 10px;
}
.token__copy__bx p {
    border: 3px solid #6e6e6e;
    padding: 5px 20px;
    border-radius: 6px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.token__image__bx {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.token__bx__cube {
    border: 3px solid #6e6e6e;
    border-radius: 6px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.token__bx__details {
    display: flex;
    gap: 22px;
    align-items: center;
}
.token__bx__circle {
    width: 40px;
    height: 40px;
    background-color: #365eb6;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.token__details__txt p {
    color: #fff;
    font-size: 14px;
    margin-bottom: 8px;
}
.token__details__txt h6 {
    font-size: 20px;
    margin-bottom: 0;
}
.token__bx__details__yellow .token__bx__circle {
    background-color: #b7a906;
}

.token__bx__details__green .token__bx__circle {
    background-color: #437507;
}
.token__bx__details___skyblue .token__bx__circle {
    background-color: #148f6a;
}
.swap__section {
    background-image: url(../images/home/swap__bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 50px;
}
.swap__bx__horizontal {
    background-color: #222222;
    padding: 10px 15px;
    border-radius: 19px;
    margin-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
/* tab css  */
.swap__container__frm__bx {
    background-color: #6262622e;
    padding: 15px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.swap__container__frm__bx .nav-tabs .nav-link {
    width: 50%;
    background-color: transparent;
    border: 0px solid #fff;
    /* border-radius: 30px; */
    color: #ffffff;
}

.swap__container__frm__bx .nav-tabs .nav-link.active {
    background-color: transparent;
    border: 0px solid #00e5f1;
    border-radius: 0;
    color: #00e5f1;
}

.swap__container__frm__bx .tab-content.bg-light{
    background-color: #22222200 !important;
    border: 0px solid #00e5f1 !important;
    border-radius: 0;
}
.swap__container__frm__bx .nav.nav-tabs{
    border: none;
}
.from__txt__bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.from__txt__bx .select__bx {
    width: auto;
    background-color: transparent;
    padding: 0px;
    border-radius: 0;
}
.from__txt__bx .dropdown.cust__drp__coin button {
    color: #ffffff;
    font-size: 12px;
}
.you__send__bx__frm__field {
    border: 1px solid #686868;
    padding: 10px 20px;
    border-radius: 8px;
}
.txt__field__bx {
    width: 60%;
    padding: 8px;
    border-radius: 8px 0px 0px 8px;
}
.color__white{
    color: #fff;
}
.color__white:focus {
    color: #d3d3d3;
}
.cust__drp__coin{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
}
.dropdown.cust__drp__coin button{
    color: #ffffff;
    padding: 0;
    font-size: 22px;
}
.txt__field__bx input{
    font-size: 24px;

}
.exchange-calculator--fields-section__sequence {
    justify-content: space-between;
    align-items: center;
    position: initial;
    display: flex;
}
.new-stepper-hints {
    padding-inline-start: 16px;
}
.new-stepper-hints_dark .new-stepper-hints__wrapper-border {
    border-inline-start: 1px solid #3E3F57;
}
.new-stepper-hints__wrapper-border {
    display: flex;
    flex-direction: column;
    border-inline-start: 1px solid #DCE2EA;
    padding: 10px 0;
}
.new-stepper-hints_dark .new-stepper-hints__item {
    color: #fff;
}
.new-stepper-hints_dark .new-stepper-hints__item:before {
    background: #3E3F57;
    top: 11px;
}
.new-stepper-hints__item:before {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    left: -4px;
    top: 9px;
    border-radius: 50%;
    background: #DCE2EA;
}
.new-stepper-hints__label {
    border: none;
    padding: 0;
    outline: none;
    background: none;
    border-bottom: 1px dashed #00B67A;
    color: #fff;
}

.new-stepper-hints__item {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-inline-start: 16px;
    padding-inline-end: 0;
}
.new-stepper-hints__item {
    position: relative;
    font-size: 12px;
    color: #808086;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-inline-start: 16px;
    padding-inline-end: 0;
    display: flex;
}
.new-stepper-button-swap_dark {
    background: #3E3F57;
}
.new-stepper-button-swap {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    width: 28px;
    height: 28px;
    border-radius: 5px;
    background: #fff;
    padding: 0;
    box-shadow: 1px 1px 6px rgba(43, 43, 54, 0.15);
}
.select__bx {
    width: 40%;
    border-radius: 0px 8px 8px 0px;
}
.from__txt__bx p {
    font-size: 12px;
}
.max__bx__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
p.max__txt__swap {
    background-color: #404040;
    padding: 0px 10px;
    border-radius: 60px;
    font-size: 14px;
    margin-bottom: 0;
}
.btn__swap__bx {
    background-color: #00e5f1;
    width: 100%;
    margin-top: 20px;
    border-radius: 40px;
    font-weight: 700;
    font-size: 18px;
    color: #323232;
}
.btn__swap__bx:hover {
    background: linear-gradient(256deg, #00e5f1 -4.64%, #1b6975ba 54.33%);
}
.btn__exchange {
    border: 1px solid #00e5f1;
    color: #fff;
    background-color: #1b6975;
    padding: 10px 15px;
    border-radius: 60px;
    text-decoration: none;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}
.you__send__bx__frm {
    background-color: #3838384a;
    padding: 15px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 30px;
}
.btn__addrecepient__add {
    background-color: #424242;
    width: 100%;
    margin-top: 20px;
    border-radius: 30px;
    color: #e3e3e3;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.btn__addrecepient__add:hover {
    background: linear-gradient(256deg, #00e5f1 -4.64%, #1b6975ba 54.33%);
    color: #00e5f1;
}
.btn__exchange:hover{
    background: linear-gradient(256deg, #00e5f1 -4.64%, #1b6975ba 54.33%);
    color: #00e5f1;
}   
.txt__field__bx .form-control:focus {
    box-shadow: 0 0 0 .25rem rgb(0 0 0 / 0%);
}
.cust__drp__coin .dropdown-menu.show {
    width: 100%;
}
.swap__icon__bx {
    text-align: center;
}
.swap__icon__bx img{
    width: 40px;
    display: inline-block;
    background-color: #404040;
    border-radius: 60px;
    padding: 5px;
}
img.aanand__icon__banner {
    width: 25px;
}
.banner__btn__l__app .launch__app__bx:hover , .banner__bx:hover {
    background: linear-gradient(256deg, #00e5f1 -4.64%, #1b6975ba 54.33%);
}

.download__app__bx {
    margin-top: 20px;
    display: flex;
    gap: 15px;
}
.download__app__bx a {
    border: 1px solid #00e5f1;
    color: #00e5f1;
    background-color: #1b6975;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    position: relative;
    padding-right: 35px;
}
.coming__soon__tag__footer{
    width: 52px !important;
    position: absolute;
    top: -5px;
    right: -6px;
}
.prod__banner__img__bx {
    text-align: center;
}

.download__app__bx a:hover{
    background: linear-gradient(256deg, #00e5f1 -4.64%, #1b6975ba 54.33%);
}

.download__app__bx a img {
    width: 20px;
    margin-left: 5px;
}

.get__touch__section {
    background-color: #161616;
    padding-top: 90px;
}
.get__email__bx {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 30px;
}
.get__in__touch__email__bx {
    margin-top: 50px;
}
.cust_-footer__form .form-control{
    background-color: #26282c;
    border: none;
    color: #fff;
    display: block;
    font-size: 14px;
    height: 48px;
    padding: 6px 15px;
    width: 100%;
}
.btn__submit__frm {
    border: 1px solid #00e5f1;
    color: #00e5f1;
    background-color: #1b6975;
    padding: 10px 15px;
    border-radius: 60px;
    text-decoration: none;
    width: 200px;
}
.btn__submit__frm:hover {
    background: linear-gradient(256deg, #00e5f1 -4.64%, #1b6975ba 54.33%);
}

.cust_-footer__form .form-control:focus {
    box-shadow: 0 0 0 .25rem #00e5f147;
}

.footer__links__bx h6 {
    font-weight: 700;
}
.digital__effert__head{
    display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: column;
}
.buy__and__banner__btn .banner__bx{
    margin-bottom: 15px;
}
.banner__txt__p {
    color: #fff;
    padding: 0px 3px;
    font-size: 13px;
}

/* product page css start  */
.product__banner__section{
    background-image: url(../images/home/hero-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 90px;
    padding-bottom: 50px;
}
.browser__extension__section{
    background-color: #161616;
    padding-top: 90px;
    padding-bottom: 50px;
}
.browser__extension__bx {
    background: #2B2B37;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    padding: 15px;
    text-align: center;
}
.browser__extension__bx img {
    width: 70px;
    margin-bottom: 15px;
}
.browser__extension__bx p{
    font-size: 18px;
    font-weight: 600;
}
.multi__chain__experience__container {
    background-image: linear-gradient(to right, #284a54, #1a445a, #1d3b5d, #2f305b, #422150);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 90px;
    padding-bottom: 50px;
    border-radius: 30px;
}
.multi__chain__experience__container .digital__effert__head p{
        margin-bottom: 30px;
}
.support__chain__bx {
    background-color: #5c5c5c;
    padding: 15px 4px;
    border-radius: 6px;
    width: 100%;
    text-align: center;
    height: 100%;
}
.support__chain__bx img{
    width: 50px;
}
.support__chain__bx p{
    font-size: 14px;
    margin-top: 6px;
    font-weight: 500;
}
.support__chain__container {
    background-color: #222222;
    padding: 30px;
    border-radius: 10px;
    margin: 50px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.coming__soon__tag {
    width: 60px;
    position: absolute;
    top: -5px;
    right: -6px;
}
.buy__crypto__section__bx{
    background-image: url(../images/home/digital-effert.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 90px;
    padding-bottom: 50px;
}
.buy__crypto__bx__container {
    background: #2B2B37;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    padding: 24px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
}   
.wallet__keys__crypto__bx:hover {
    background: linear-gradient(256deg, #464646 -4.64%, #000000ba 54.33%);
}
.buy__crypto__bx__container h4 {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: 600;
}
.buy__crypto__bx__container p {
    font-size: 14px;
    margin-bottom: 30px;
}
.buy__crypto__bx__container img {
    width: 80px;
}
.wallet__keys__section {
    background-color: #161616;
    padding-top: 90px;
    padding-bottom: 50px;
}
.wallet__banner__bx h1 {
    font-size: 35px;
}   
.wallet__banner__bx p {
    font-size: 16px;
}
.wallet__keys__crypto__bx {
    background-color: #222222;
    border-radius: 19px;
    margin-bottom: 30px;
    border: 1px solid #d5d5d5;
    padding: 30px;
    width: 100%;
}
.crypto__wallet__img {
    text-align: center;
}
.crypto__wallet__img img {
    width: 250px;
}
.crypto__wallet__txt {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.crypto__wallet__txt h3 {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 20px;
}
.crypto__wallet__txt p {
    font-size: 18px;
}

.copy__right__footer__container {
    margin-top: 30px;
}
.copy__right__footer__container p {
    font-size: 14px;
}   





/* toggle css language  */
.toggle__language {
    display: flex;
    align-items: center;
}

.toggle__language .switch {
    position: relative;
    display: inline-block;
    margin: 0 5px;
  }
  
  .toggle__language .switch > span {
    position: absolute;
    top: 14px;
    pointer-events: none;
    font-family: 'Helvetica', Arial, sans-serif;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
    width: 50%;
    text-align: center;
  }
  
  .toggle__language input.check-toggle-round-flat:checked ~ .off {
    color: #00e5f1; /* Cyan when checked */
  }
  
  .toggle__language input.check-toggle-round-flat:checked ~ .on {
    color: #fff;
  }
  
  .toggle__language .switch > span.on {
    left: 0;
    padding-left: 2px;
    color: #00e5f1; /* Default Cyan */
  }
  
  .toggle__language .switch > span.off {
    right: 0;
    padding-right: 4px;
    color: #fff;
  }
  
  .toggle__language .check-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }
  
  .toggle__language .check-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
  }
  
  .toggle__language input.check-toggle-round-flat + label {
    padding: 2px;
    width: 97px;
    height: 45px;
    background-color: #1b6975; /* Dark teal */
    border-radius: 60px;
  }
  
  .toggle__language input.check-toggle-round-flat + label:before,
  .toggle__language input.check-toggle-round-flat + label:after {
    display: block;
    position: absolute;
    content: "";
  }
  
  .toggle__language input.check-toggle-round-flat + label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #1b6975;
    border-radius: 60px;
  }
  
  .toggle__language input.check-toggle-round-flat + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 48px;
    background-color: #000000; /* Bright cyan */
    border-radius: 52px;
    transition: margin 0.2s, background-color 0.3s;
  }
  
  .toggle__language input.check-toggle-round-flat:checked + label {
    background-color: #00e5f1; /* Active state background */
  }
  
  .toggle__language input.check-toggle-round-flat:checked + label:after {
    margin-left: 44px;
    background-color: #161616; /* Dark toggle ball */
  }
  
.k-pop-section .platform__f__bx img{
    width: 75px;
    background-color: #4c4c4c;
    border-radius: 100px;
    padding: 12px;
}
.buy__crypto__bx__container{
    transition: transform ease 500ms;
    border: 2px solid #6e6e6e00;
}
.buy__crypto__bx__container:hover{
    transform: translate(0, -10px);
    background: linear-gradient(253deg, #0e0e0e -4.64%, #2c2c2cba 54.33%);
    border: 2px solid #00e5f1;
}
.multi__chain__experience__container {
    padding-right: 30px;
    padding-left: 30px;
}
.gradient__bg{
    background-image: linear-gradient(to right, #284a54, #1a445a, #1d3b5d, #2f305b, #422150);
}
.wallet__section {
    padding-top: 90px;
    padding-bottom: 50px;
    background-color: #000000;
}
.wallet__container__bx {
    background-image: linear-gradient(to right, #284a54, #1a445a, #1d3b5d, #2f305b, #422150);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 50px;
    border-radius: 10px;
}
.wallet__download__section .steps__digital__img img{
    width: 60px;
    border: 0px solid #d5d5d5;
    border-radius: 19px;
    margin-bottom: 10px;
}

.wallet__download__section .steps__digital__txt.mt-auto{
    text-align: left;
    margin-top: 15px !important;
}

.wallet__download__section .steps__digital__txt h6 {
    font-size: 20px;
    text-align: left;
    margin-bottom: 8px;
    color: #fff;
}
.wallet__download__section .steps__digital__txt p {
    font-size: 16px;
    text-align: left;
    color: #fff;
}
.steps__bx__container:hover {
    background: linear-gradient(256deg, #00e5f1 -4.64%, #1b6975ba 54.33%);
}
.coming__soon__app__download{
    width: 120px !important;
    position: absolute;
    top: -8px;
    right: -9px;
}


/* media css start  */
@media screen and (max-width: 1336px) {

}

@media screen and (max-width: 991px) {
    .banner__qube__img__section img {
        width: 100%;
    }
    .token__bx__details {
        margin-bottom: 30px;
    }
    .select__bx {
        width: auto;
    }
    .crypto__wallet__img img {
        width: 100%;
    }
    .hero__banner__head {
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 767px) {
    .hero__banner__head h1 {
        font-size: 22px;
    }
    .hero__banner__head p {
        font-size: 16px;
        text-align: center;
        color: #d5d5d5;
    }
    .banner__bx {
        background-color: #1b69751f;
    }
    .token__bx__details {
        display: flex;
        gap: 22px;
        align-items: center;
        margin-bottom: 30px;
    }
    .footer__second__bx {
        margin-bottom: 30px;
    }
    .problem__project__head h2 {
        font-size: 22px;
    }
    .digital__effert__head h3 {
        font-size: 22px;
    }
    .platform__head h3 {
        font-size: 22px;
    }
    .platform__head p {
        font-size: 16px;
    }
    .banner__bx {
        width: 100%;
    }
    .download__app__bx a {
        padding: 14px 11px;
        font-size: 13px;
    }
    .download__app__bx a img {
        width: 15px;
    }
    .crypto__wallet__img img {
        width: 160px;
        margin-bottom: 30px;
    }
    .banner__qube__img__section img {
        width: 100%;
        margin-top: 35px;
        margin-bottom: 30px;
    }
    .banner__bx {
        padding: 10px 15px;
    }
    .banner__bx__head h4 {
        font-size: 16px;
    }
    .coming__soon__tag {
        width: 57px;
    }
    .token__image__bx img.w-100 {
        width: 270px !important;
        margin-bottom: 90px;
    }
    .token__copy__bx p {
        padding: 5px 10px;
    }
    .digital__effert__head p {
        font-size: 16px;
    }
    .header__section span.navbar-text.d-flex.align-items-center{
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 20px
    }
    .wallet__banner__bx h1 {
        font-size: 22px;
    }
    .wallet__banner__bx p {
        font-size: 16px;
    }
    .steps__bx__container {
        padding: 10px 15px;
    }
    .wallet__download__section .steps__digital__img img {
        width: 50px;
    }
    .coming__soon__app__download {
        width: 110px !important;
    }
    .wallet__download__section .steps__digital__txt h6 {
        font-size: 22px;
    }
    .wallet__download__section .steps__digital__txt p {
        font-size: 16px;
    }
    .buy__crypto__bx__container img {
        width: 80px;
    }
    .buy__crypto__bx__container p {
        text-align: center;
    }
    .crypto__wallet__txt h3 {
        font-size: 22px;
    }
    .crypto__wallet__txt p {
        font-size: 16px;
    }
    .browser__extension__bx img {
        width: 70px;
    }
    .support__chain__container {
        padding: 30px;
        margin: 15px 0px;
    }

    .hero__banner__head {
        margin-bottom: 80px;
    }
}