@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
/* @import "./variable.scss"; */

/* Base CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  font-size: 100%;
  /* font: inherit; */
  vertical-align: baseline;
  outline: none;
}

html,
body {
  height: 100%;
  width: 100%;
  line-height: 1;
}

body {
  font-family: 'Inter';
  /* background: #f5f5f5; */
  color: #000;
  background: rgb(208,240,247);
background: linear-gradient(37deg, rgba(208,240,247,1) 0%, rgba(237,241,240,1) 100%);
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img,
video {
  max-width: 100%;
  height: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button,
input,
textarea,
select {
  font: inherit;
  background: none;
  color: inherit;
}

:root {
  --base-padding: 0;
  --base-margin: 0;
}

.body-container {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  height: calc(100vh - 102px);
}

body {
  background-color: #F2F5F8;
  font-size: 14px;
  line-height: 23px;
  font-weight: normal;
}

.dashboard-page {
  /* background-color: #ccc; */
}

.dashboard-page {
  display: flex;
  width: 100%;
  gap: 20px;
}

.sidebar-menu {
  background-color: #000;
  min-width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
}

.sidebar-menu ul {
  color: #fff;
  margin-top: 40px;
  margin-left: 0;
  padding-left: 0;
}

.sidebar-menu ul li a {
  padding: 14px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #2B2E38;
}

.sidebar-menu ul li a img {
  filter: brightness(0) invert(1);
}

.sidebar-menu ul li a.active img {
  filter: unset;
}

.sidebar-menu ul li a.active {
  border-bottom: 1px solid #F0B90B;
  background-color: #272935;
}
.darktheme .connect-button-side.and-connect{
  background-color: #07080a;
}
.connect-button-side {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  color: #fff;
  background-color: #321902;
  border-radius: 8px;
  padding: 12px 10px;
  position: relative;
}

.content-connect {
  display: flex;
  align-items: center;
  gap: 10px;
}

.connect-name h5 {
  font-size: 14px;
  font-weight: 500;
}

.connect-name p {
  margin-bottom: 0;
  font-size: 12px;
  opacity: 0.7;
}
.smalltext-p{
  font-size: 12px;
}

.active-green {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #33A852;
  border-radius: 30px;
  right: 10px;
  top: 10px;
  position: absolute;
}

.content-page {
  width: 100%;
  padding-right: 20px;

}

.content-page-inner {
  width: 100%;
  display: flex;
  gap: 20px;
  min-height: calc(100vh - 165px);
}

.connect-button-side.and-connect {
  background-color: #1F2129;
  margin-bottom: 15px;
}

.portfolio-section {


  width: 100%;
  min-width: 325px;
  max-width: 325px;
}

.total-assets {
  border: 1px solid #F2EEEE;
  padding: 10px 14px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total-assets p {
  color: #647480;
  font-size: 12px;
  margin-bottom: 0;
}

.total-assets h2 {
  font-size: 28px;
  font-weight: 500;
  color: #111111;
  margin-bottom: 0;
}

.portfolio-section-head {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafbfe;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid #eceef7;
}
.portfolio-section-head h3{
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.portfolio-section-head .total-assets{
  padding: 6px 14px;
  display: block;
  border: none;
  text-align: right;
}

.portfolio-section-head .total-assets h2{
  font-size: 20px;
  font-weight: 500;
}


.portfolio-list-main-dv{
  max-height: calc(100vh - 261px);
  overflow: auto;
}
.listing-portfolio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #F2F5F8;
  padding: 12px 15px;
}

.listing-portfolio-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.listing-portfolio-icons h5 {
  margin-bottom: 0px;
  line-height: normal;
  font-size: 14px;
  font-weight: 600;
}

.listing-portfolio-icons p {
  margin-bottom: 0;
  font-size: 12px;
  color: #111111;
  opacity: 0.6;
}
.listing-portfolio-icons h5 span {
  margin-bottom: 0;
  font-size: 11px;
  color: #111111;
  opacity: 1;
  background-color: #e1e4e9;
  padding: 4px 8px;
  margin-left: 10px;
  font-weight: normal;
  border-radius: 4px;
}
.walleticon-circle {
  position: relative;
  width: 34px;
  height: 34px;
}

.walleticon-circle img {
  width: 34px;
  height: 34px;
}

.walleticon-circle img.networkicon-port {
  width: 14px;
  height: 14px;
  position: absolute;
  right: -5px;
  bottom: 3px;
}

.contentcoins h5 {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 600;
  line-height: auto;
}

.contentcoins p {
  margin-bottom: 0;
  font-size: 12px;
  color: #111111;
  opacity: 0.9;
  line-height: normal;
}

.listing-portfolio-right {
  font-size: 16px;
  color: #111111;
  font-weight: 500;
}


.right-section-web {
  width: 100%;

}

.right-inner {
  display: flex;
  gap: 20px;
  width: 100%;
}

.send-receive-section {
  border-radius: 20px;
  padding: 20px 30px;
  background-color: #fff;
  width: 100%;
}

.send-receive-section .tablist {
  border-bottom: 1px solid #E5E9EC;
}

.send-receive-section .tablist button {
  padding: 10px 20px;
  color: #93A5B2;
  cursor: pointer;
}

.send-receive-section .tablist button.active {
  color: #000;
  border-bottom: 2px solid #000;
}

.send-receive-section .tablist button:hover {
  color: #000;
  border-bottom: 2px solid #000;
}

.tab-content-dv {
  padding: 20px 0;
}

.input-form {
  margin-bottom: 20px;
}

.input-form label {
  /* font-size: 12px; */
  width: 100%;
}

.input-form input {
  width: 100%;
  height: 40px;
  border: 1px solid #DAE0EE;
  border-radius: 6px;
  padding: 10px 15px;
  color: #000;
}
.input-form input:disabled{
  opacity: 0.4;
  background-color: #DAE0EE;
  color: #000;
}
.btn-send {
  max-width: 210px;
  width: 100%;
  margin: 30px auto 0;
  padding: 12px 20px;
  text-align: center;
  background-color: #0E0E13;
  border: 1px solid #0E0E13;
  color: #fff;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 25px;
 
}
.btn-send:disabled{
  background-color: #eceef5;
  border: 1px solid #eceef5;
  color: #848895;
  pointer-events: none;
}

.darktheme .btn-send:disabled{
  background-color: rgb(14 14 19 / 35%);
  border: 1px solid rgb(14 14 19 / 32%);
  color: #8a8a8a;
  pointer-events: none;
}
.btn-send:hover {
  background-color: #fff;
  border: 1px solid #0E0E13;
  color: #0E0E13;
}

.darktheme .homepage{
  color: #000;
}

.darktheme .problems-solvedby{
  color: #fff;
  background-color: #000;
}
.darktheme .problems-solvedby ul li {
  color: #fffbe9;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.limited-offer-text {
  display: flex;
  align-items: center;
  gap: 10px;
}
.select-opsn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 6px 10px;
}

.select-opsn img {
  max-width: 100%;
}

.flex-opens {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flex-opens img {
  width: 24px;
}

.select-opsn svg {
  width: 25px;
}

.body-selects {
  background-color: #fff;
  padding: 20px;
  width: 200px;
  text-align: left;

}

.selectstyle {
  position: relative;
}

.mb-3 {
  margin-bottom: 10px;
}

.qrcode {
  margin: 20px auto;
  text-align: center;
}

.addressnm {
  color: #7F7F7F;
}

.address-label {
  border-right: 1px solid #d7dbec;
  padding-right: 10px;
  color: #2D2468;
  font-weight: 600;
}

.request-id-content {
  border: 1px solid #DAE0EE;
  border-radius: 6px;
  padding: 5px 5px 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-address {
  display: flex;
  align-items: center;
  gap: 10px;
}

.copybutton {
  width: 30px;
  height: 30px;
  display: flex;
  background-color: #EBEEFB;
  padding: 6px;
  border-radius: 4px;
  cursor: pointer;
}

.sharelink {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.link-share {
  display: inline-block;
  border-bottom: 1px solid #ccc;
  color: #0F74E0;
  word-break: break-all;
}

.bannerimg {
  min-width: 314px;
  max-width: 314px;
}

.bannerimg img {
  height: 100%;
}



.transaction-list {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
  height: calc(100% - 470px);
}

.transaction-list .header-trn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EBEEF7;
  padding: 6px 20px 16px;
  margin-bottom: 20px;
}

.transaction-list .header-trn h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.viewall-a {
  font-size: 14px;
  display: inline-block;
  text-decoration: underline;
  color: #3C55E3;
  cursor: pointer;
}



.transaction-list-table table {
  width: 100%;
}

.transaction-list-table table thead {
  background-color: #fff;
}

.transaction-list-table table thead tr th {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  padding: 10px 15px;
  text-align: left;
}

.transaction-list-table table tbody tr {
  background-color: #EEF1F8;
  border-radius: 10px;
  border-bottom: 3px solid #fff;
}

.transaction-list-table table tbody tr td {
  font-size: 14px;
  font-weight: normal;
  color: #000;
  text-align: left;
  padding: 10px 15px;
}

.transaction-list-table table tbody tr td:first-child {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;

}

.transaction-list-table table tbody tr td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.flex-type {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flex-type img {
  width: 20px;
}

.status-pending {
  display: inline-block;
  border-radius: 8px;
  padding: 4px 15px;
  color: #F7931A;
  background-color: #FFF4E7;
}

.status-successful {
  display: inline-block;
  border-radius: 8px;
  padding: 4px 15px;
  color: #0DD01A;
  background-color: #E7F8F0;
}


.walletbutton {
  display: inline-block;
  padding: 12px 30px;
  background-color: #f2bf2e;
    color: #000000;
  font-size: 14px;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
}

.connect-walletbtn {
  display: flex;
      padding: 10px;
      justify-content: space-between;
      gap: 30px;
}

.footer-section {
  background-color: #fff;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-socials {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flex-list-support {
  display: flex;
  align-items: center;
  gap: 20px;
}

.support-label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}


/**Swap Modal css*/

.swapmodal{
  width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 165px);
  }
  .swap-modal-main{
    min-width: 500px;
    /* background-color: #fff; */
    /* padding: 30px; */
    position: relative;
    /* border-radius: 24px; */
    max-width: 500px;
  }
  .logo-swap-buy{
    text-align: center;
  }
  
  .closebtn{
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  }
  .selectflag{
    position: relative;
    margin-bottom: 20px;
  }
  .label-txt{
    font-size: 14px;
  }
  .select-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 10px 10px;
  }
  .select-head svg{
    width: 20px;
  }
  .select-head .flex-opens{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .select-body{
    background-color: #fff;
  
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 6px;
  }
   ul.select-body li .flex-opens{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 15px;
    border-bottom: 1px solid #ebedf2;
    cursor: pointer;
  }
  ul.select-body li:last-child .flex-opens{
    border-bottom: none;
  }
  .rotate-180{
    transform: rotate(180deg); 
  }
  .dllr-app-amt{
    position: absolute;
    top: 33px;
    color: #b5b8cd;
    right: 15px;
  }
  
  .crypto-input{
    display: flex;
    gap: 15px;
  }
  .input-form .listing-portfolio-left{
    border: 1px solid #E1E4EC;
    border-radius: 8px;
    width: 150px;
      min-width: 150px;
    padding: 6px 10px;
    cursor: pointer;
  }
  .input-skeltn{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
  .input-skeltn .skeleton-loader{
    height: 50px;
  }

  .input-form p .skeleton-loader{ width: 100px; display: inline-block; margin-top: 5px;}
  
  .input-form .crypto-input input{
  font-size: 26px;
  height: 50px;
  }
  .input-form p{
    text-align: right;
    font-size: 12px;
  }
  
  .swap-modal-main .input-form label{
    font-size: 14px;
  }
  .swapmodal-p{
    text-align: center;
    font-size: 12px;
    max-width: 360px;
    margin: 25px auto 10px;
  }
  .swap-modal-main .tab-content-dv{
    padding: 25px 0 0;
  }
  
  .swap-modal-main .send-receive-section{
  padding: 30px;
  /* min-height: 300px; */
  min-height: 590px;
  position: relative;
  }
  .flexinputdiv{
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 20px;
  }
  .flexinputdiv .input-form{
    margin-bottom: 0;
  width: 100%;
  }
  
  
  
  /* Modal Styles (Modal.css) */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);  /* semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .open-modal-btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .open-modal-btn:hover {
    background-color: #45a049;
  }
  
  .close-btn {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-btn:hover {
    background-color: #e53935;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  .overlay-modals {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 65%);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
  .networkmodal {
    min-width: 460px;
    max-width: 460px;
    /* background-color: #fff; */
    padding: 30px;
    border-radius: 20px;
  }
  
  .networkmodal-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .networkmodal-header h3{
    font-size: 24px;
    margin-bottom: 0;
  }
  .networkmodal .closebtns {
    cursor: pointer;
    background-color: #fff;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.darktheme .networkmodal .closebtns {

  background-color: #20222a;

}

  .networklist-ul{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  .networkmodal-body {
      
  }
  .networktitle{
    display: flex;
        align-items: center;
        gap: 10px;
        margin: 0px 0 20px;
        font-size: 16px;
  }
  .networktitle img{
    min-width: 20px;
    max-width: 20px;
  }
  .networklist {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #f3f5fa;
    background-color: #f3f5fa;
    border-radius: 6px;
    padding: 8px 10px;
    cursor: pointer;
  }

  .networklist.active{
      background-color: #fff1eb;
      border: 1px solid #ff6717;
      box-shadow: -1px 5px 3px #ff580014;
  }
  .darktheme .networklist.active{
    background-color: #fff1eb;
    border: 1px solid #fff1eb;
    color: #000;
    box-shadow: -1px 5px 3px #ff580014;
}

  
  .networklist img{
    width: 20px;
  }
  .closebtns{
    cursor: pointer;
  }
  
  















[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #000;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.debit-card{
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #dde7f6;
  border-radius: 10px;
}



.tab-content-main{
  /* background-color: #fff; */
  padding: 0;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
}
.arrowdown {
  width: 20px;
}


 .coming-soon{
  transform: rotate(0deg);
  width: 100%;
  height: 100%;
  font-size: 12px;
  background-color: rgb(255 255 255 / 82%);
  padding: 0px 8px;
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: center;
  z-index: 1;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur( 2px );
-webkit-backdrop-filter: blur( 2px );
color: #000;
}

 
.swap-modal-main .tablist{
border-radius: 30px;
  background-color: #fff;
  margin: 0 auto 15px;
  position: relative;
  left: 0;
  right: 0;
  width: 360px;
  display: block;

}
.swap-modal-main .tablist button{
  padding: 10px 20px;
    color: #93A5B2;
    cursor: pointer;
    margin-right: 0px;
    border-radius: 45px;
    width: 120px;
}
.estimated-text {
  display: flex;
  margin-bottom: 20px;
}
.estimated-text h3{
  font-size: 12px;
  margin-bottom: 0;
}
.estimated-text p{
  margin-bottom: 0;
}
.total-and-value {
 font-weight: 300;
  font-size: 12px;
  text-align: right;
}


.searchbox input{
  width: 100%;
  position: relative;
  height: 46px;
  background-color: #fff;
  border-radius: 12px;
  padding: 10px 15px;
}
.input-form.small-icon .walleticon-circle{
  width: 24px;
  height: 24px;  
}
.input-form.small-icon .walleticon-circle img{
  width: 24px;
  height: 24px;
}
.input-form.small-icon .walleticon-circle .networkicon-port{
  width: 14px;
  height: 14px;
  right: -5px;
    bottom: 0px;
}
.input-form.small-icon .crypto-input input{
  height: 40px;
  font-size: 14px;
}






.card-banr {
  position: relative;
  height: 256px;
  overflow: hidden;
}

.card-banr img{
  max-width: 100%;
}
.slick-track{
  display: flex;
}
.slick-arrow{
  display: none !important;
}
.listing-main-div{
  background-color: #fff;
  border-radius: 16px;
  height: calc(100% - 251px);
}



.swappadding{
  padding: 30px;
}

















.togglebutton {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 5px;
  padding: 14px;
}

.togglebutton input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 40px;
    height: 20px;
    background: #ccc;
    border-radius: 50px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: 0.4s;
}

.togglebutton input:checked[type="checkbox"] {
    background: #7da6ff;
}

.togglebutton input[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
    transition: 0.4s;
}

.togglebutton input:checked[type="checkbox"]::after {
    left: 50%;
}

/* body:after{
  content: '';
  background-color: rgb(255 255 255 / 62%);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(2px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

.bannerimg .card-banr{
  height: 460px;
}

.bannerimg .card-banr img{
  height: 100%;
}
.qrcode svg{
  height: 190px;
}
.searchbox {
  position: relative;
  min-width: 350px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.searchbox button{
  position: absolute;
  right: 10px;
  top: 12px;
  border: none;
}
.searchrightbutton{
  display: flex;
  align-items: center;
  gap: 10px;
}

.connect-walletbtn .togglebutton{
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0;
}
.connect-walletbtn .togglebutton .check-box{
  margin-top: 5px;
}



.connect-sidebar h4{
  text-align: center;
  color: #fffbe9;
  margin-bottom: 10px;
}
.darktheme .sidebar-menu{
background-color: #1d2129;
}
.darktheme .send-receive-section{
background-color: #1d2129;
}

.darktheme .send-receive-section .tablist button.active{
  color: #eec48f;
  border-bottom: 2px solid #eec48f;
}
.darktheme .listing-portfolio-icons h5{
  color: #eec48f;
}

.darktheme  .send-receive-section .tablist button{
  color: #52504e;
}

.darktheme .tab-content-main{
  background-color: transparent;
}
.darktheme .send-receive-section .tablist{
  border-bottom: 1px solid #212837;
}
.darktheme .input-form label{
  color: #eec48f;
}
.darktheme .input-form input{
  border: 1px solid #414752;
  background-color: #262b34;
  color: #fffbe9;
}
.darktheme .input-form p{
  color: #eec48f;
}
.darktheme .input-form input:disabled {
  opacity: 0.4;
  background-color: #212837;
  color: #fffbe9;
}
.darktheme .input-form .listing-portfolio-left{
  border: 1px solid #17181f;
  background-color: rgb(23 24 31 / 71%);
  border-radius: 11px;
  width: 150px;
    min-width: 150px;
}
.darktheme .networkmodal{
background-color: transparent;
}
.darktheme .networklist{
  align-items: center;
  gap: 10px;
  border: 1px solid #131418;
  border-radius: 10px;
  padding: 8px 10px;
  cursor: pointer;
  background-color: #131418;
}
 .row-class{
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 0px;
  border-radius: 20px;
  margin-bottom: 20px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.darktheme .row-class{
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  background-color: rgb(29 32 40 / 82%);
  padding: 20px;
  margin-bottom: 0px;
  border-radius: 20px;
  margin-bottom: 20px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}
.overlay-modals.left-side{
  padding-left: 240px;
  /* align-items: flex-start; */
  padding-top: 40px;
}
body.darktheme{
  color: #fffbe9;
  background: #121419;
  background: linear-gradient(37deg, #1a1d24 0%, #000000 100%);
}
.darktheme .portfolio-section-head{
  background-color: #292e38;
  border-bottom: 1px solid #363c47;
}
.darktheme .transaction-list-table table tbody tr{
  border-bottom: 3px solid #1d2129;
}
.darktheme .total-assets p{
  color: #fff; opacity: 0.5;
}
.darktheme .total-assets h2{
  color: #fff;
}
.darktheme .coming-soon{
  background-color: rgb(0 0 0 / 82%);
color: #eac28e;
}
.darktheme .transaction-list{
background-color: #1d2129;
color: #fffbe9;
}
.darktheme .transaction-list .header-trn{
  border-bottom: 1px solid #303640;
    color: #ceb891;
}
.darktheme .viewall-a{color: #eec48f;}
.darktheme .transaction-list-table table thead {
  background-color: transparent;
}
.darktheme .togglebutton input:checked[type="checkbox"]{
background: #3a6869;
}
.darktheme .transaction-list-table table thead tr th{
  color: #fffbe9;
}
.darktheme .send-receive-section .tablist button:hover {
  color: #eec48f;
  border-bottom: 2px solid #eec48f;
}
.darktheme .transaction-list-table table tbody tr {
  background-color: #212837;
  border-radius: 10px;
}

.darktheme .select-opsn{
  border: 1px solid #322e2b;
}


.darktheme .transaction-list-table table tbody tr td{
  color: #fffbe9;
}

.darktheme .listing-main-div {
  background-color: #1d2129;
  color: #fffbe9;
}

.darktheme .listing-portfolio-icons p{
  color: #eed097;
  line-height: normal;
}
.darktheme  .arrowdown img{
  filter: brightness(0) invert(1);
  width: 20px;
  opacity: 0.5;
}
.darktheme .listing-portfolio-icons h5 span {
  color: #ceb891;
  background-color: #214647;
}
.darktheme .listing-portfolio{
  border-bottom: 1px solid #212837;
}
.darktheme .listing-portfolio-right{
  color: #fffbe9;
}

.darktheme .footer-section{
  background-color: #1d2129;
  color: #fffbe9;
}

.darktheme .address-label{
  color: #eec48f;
}
.darktheme .addressnm {
  color: #eec48f;
}
.darktheme .copybutton{
  background-color: #214647;
}
.darktheme .request-id-content{
  border: 1px solid #454c5b;
}
.darktheme .address-label{
  border-right: 1px solid #454c5b;
}
.darktheme .copybutton img{
  filter: brightness(0) invert(1);
}

.swap-modal-main .tablist{
  /* background-color: #212837; */
}


.darktheme .searchbox input{
background-color: #212837;
color: #fffbe9;
}

.darktheme .searchbox img{
  filter: brightness(0) invert(1);
}

.darktheme .btn-send {
  background-color: transparent;
  color: #eec48f;
  max-width: 210px;
  margin: 30px auto 0;
  border: 1px solid #eec48f;
}

.darktheme .btn-send:hover{
  background-color: #eec48f;
  color: #000;
  border: 1px solid #eec48f;
}


.darktheme input::placeholder{
  color: #eec48f;
  opacity: 0.4;
  font-weight: 300;
}



.main-inputbox {
  /* background-color: #191b22; */
  background-color: #fff;
  padding: 20px;
  margin-bottom: 0px;
  border-radius: 20px;
  margin-bottom: 5px;
  backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
}

.darktheme .main-inputbox {
  /* background-color: #191b22; */
  background-color: rgb(29 32 40 / 82%);
}


.text-right-label label{
text-align: right;
display: block;
}

.darktheme .main-inputbox .input-form input{
  border: 1px solid #17181f;
  background-color: rgb(23 24 31 / 71%);
  color: #fffbe9;
  border-radius: 11px;
}


.darktheme .swap-modal-main .tablist{
  background-color: #212837;
}

.swap-modal-main .tablist button.active{
  background-color: #272935;
  color: #fff;
}

.darktheme .swap-modal-main .tablist button.active{
  background-color: #275051;
  color: #f9d694;
}

.darktheme .estimated-text {
      background-color: #275051;
      color: #eec48f;
}
.estimated-text {
  display: flex;
      gap: 6px;
      background-color: #edeff3;
      padding: 2px 10px;
      border-radius: 10px;
      font-size: 11px;
      margin: 0 auto;
      left: 0px;
      right: 0;
      position: relative;
      width: 190px;
      text-align: center;
      align-items: center;
      justify-content: center;
      top: -37px;
      color: #0e0e13;
}

.darktheme .walletbutton {
  color: #f1f1ec;
  background-color: #272935;
}

.slide-portfolio-nw{
  background-image: url('./assets/images/total-and-banner.png');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 228px;
  width: 100%;
color: #fff;
padding: 30px;
display: inline-flex !important;
flex-direction: column;
justify-content: center;
}
.slide-portfolio-nw.secound-slider{
  background-image: url('./assets/images/total-assets-banner.png');
}
.slide-portfolio-nw .inner-flex-slide h3{
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}
.slide-portfolio-nw .inner-flex-slide p{
  font-size: 14px;
  margin-bottom: 10px;
}
.slide-portfolio-nw .inner-flex-slide h6{
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}

























/* home page  */




.token-info{
  padding: 100px 0;
  background-color: #000;
  color: #fff;
}
.token-info table thead tr th{
  background-color: #000;
  border: 1px  solid #312f2f;
  color: #828894;
}
.token-info table thead tr th{
  background-color: #000;
  border: 1px  solid #312f2f;
  color: #828894;
}
.token-info table tbody tr td{
  background-color: #312f2f;
  border: 1px  solid #312f2f;
  color: #fff;
}

.homepage .logo-a img{
  height: 50px;
 }
 .homepage .hero-section-home{
   background-color: #171717;
 }
 .homepage .hero-section-home .header-menu .dropdownmenu{
   position: relative;
   display: flex;
   gap: 15px;
   align-items: center;
 }
 .homepage .hero-section-home .header-menu .dropdownmenu svg{
  width: 18px;
 }
 .homepage .hero-section-home .header-menu{
   display: flex;
     align-items: center;
     justify-content: space-between;
     width: 100%;
     margin-top: 10px;
 }
 .homepage .hero-section-home .header-menu .dropdownmenu:hover ul{
   display: block !important;
 } 
 .homepage .hero-section-home .header-menu .dropdownmenu ul{
   display: none !important;
 padding: 20px;
 background-color: #ffffff;
     color: #000;
     display: block;
     position: absolute;
     width: 200px;
     left: 0;
     top: 40px;
     border-radius: 16px;
 }
 .homepage .hero-section-home .header-menu .dropdownmenu ul li a{
   display: block;
   color: #000;
   width: 100%;
 }
 .homepage .hero-section-home .header-menu ul{
   display: flex;
   align-items: center;
   gap: 10px;
 }
 
 .homepage .hero-section-home .header-menu ul li a{
   color: #fff;
   font-size: 14px;
   display: block;
   padding: 10px 20px;
 }
 .homepage .hero-section-home .header-menu ul li a.button-dashboard{
  background-color: transparent;
  border: 1px solid #765705;
  border-radius: 30px;
  padding: 5px 20px;
 }
 
 .hero-content-div{
   color: #fff;
   padding: 80px 0;
 }
 .hero-content-div h2{
 font-size: 52px;
 font-weight: bold;
 margin-bottom: 20px;
 }
 .hero-content-div h2 span{
   color:#3b81f5;
   display: inline-block;
 }
 .hero-content-div p{
   font-size: 16px;
   line-height: 26px;
   font-weight: 300;
   letter-spacing: 0.5px;
   opacity: 0.9;
   margin-bottom: 30px;
 }
 
  .heroicons p{
  margin-bottom: 0;
  font-size: 14px;
  color: #eec48f;
  /* background-color: #303030;
  padding: 0px 10px; */
  border-radius: 4px;
 }
  .heroicons{
   display: flex;
   align-items: center;
    gap: 15px;
    justify-content: center;
    margin-top: 10px;
 }
  .heroicons .icon-i img{
  height: 40px;
  width: 40px;
 }
 
 .eventimage{
   overflow: hidden;
   border-radius: 20px;
 }
 .eventimage video{
   object-fit: cover;
   width:100%;
   height: 100%;
 }
 .problems-solvedby h3{
   margin-bottom: 20px;
 }
 .problems-solvedby{
 padding: 100px 0;
 }
 .problems-solvedby ul{
   margin-left: 0;
   padding-left: 0;
 }
 .problems-solvedby ul li{
   display: flex;
   gap: 16px;
   align-items: center;
   margin-bottom: 20px;
 }
 
 .problems-solvedby ul li span{
   width: 30px;
   min-width: 30px;
   height: 30px;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   background-color: #3b81f5;
   padding: 5px;
   border-radius: 5px;
 }
 
 
 .platform-feature{
   padding: 100px 0;
   background-color: #000;
   color: #fff;
 }
 
 .platform-feature .feature-card{
 background-color: #292828;
 padding: 30px;
 margin-bottom: 30px;
 text-align: center;
 }
 .platform-feature .feature-card h3{
   font-size: 20px;
   font-weight: 600;
 }
 .platform-feature .feature-card p{
   font-size: 14px;
   color: #b0aeae;
   margin-bottom: 0;
 }
 
 .platform-feature .feature-card img{
   margin-bottom: 20px;
   height: 200px;
 }
 
 .explore-button-div{
   text-align: center;
 }
 .explore-more-featire{
  padding: 5px 20px;
  border-radius: 36px;
  color: #ffffff;
  font-size: 14px;
  border: 1px solid #3b81f5;
  background-color: #3b81f5;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
 }
 .explore-more-featire:hover{
  background-color: #477778;
  border: 1px solid #477778;
  color: #f9d694;
 }
 .explore-more-featire img{
  width: 24px;
 }
 
 
 .limited-offer-text p{
  color: #ee5b1e;
  font-weight: 600;
  margin-bottom: 0;
 }
 
 
 .title-section-home{
   margin-bottom: 40px;
   text-align: center;
 }
 .title-section-home h2{
   font-size: 36px;
   font-weight: 600;
   margin-bottom: 6px;
 }
 .title-section-home p{
   margin-bottom: 0;
   font-size: 16px;
   color: #fffbe9;
   font-weight: 300;
 }
 
 .business-solution{
  padding: 100px 0;
  background-color: #171717;
  color: #fff;
 }
 .content-solution-b{
   padding: 15px;
 }
 .business-solution .feature-card{
 margin-bottom: 30px;
 overflow: hidden;
 border-radius: 16px;
 border: 1px solid #2b2b2b;
 }
 .business-solution .feature-card h4{
   font-size: 18px;
   font-weight: 600;
 }
 .business-solution .feature-card p{
   font-size: 14px;
   color: #787c88;
   margin-bottom: 0;
 }
 .business-solution .feature-card .business-solution-img {
   height: 260px;
   overflow: hidden;
 
 }
 .business-solution .feature-card .business-solution-img img{
   width: 100%;
   object-fit: cover;
   height: 100%;
 }
 
 .business-solution .explore-button-div{
   text-align: center;
 }
 .business-solution .explore-more-featire{
   padding: 12px 30px;
   border-radius: 6px;
   color: #fff;
   font-size: 14px;
   border: 1px solid #3b81f5;
   background-color: #3b81f5;
   display: inline-block;
 }
 
 .platform-architecture-section{
   padding: 100px 0;
   background-color: #171717;
   color: #fff;
 }
 
 .flex-flatform-c {
   display: flex;
       gap: 20px;
       align-items: center;
       padding: 30px 20px;
       background-color: #302e2e;
       border-radius: 6px;
       margin-bottom: 20px;
 }
 
 .flex-flatform-c .flexleft-c{
   min-width: 50px;
   max-width: 50px;
 }
 .flexright-c h4{
   font-size: 20px;
   font-weight: 600;
   margin-bottom: 6px;
 }
 .flexright-c p{
   font-size: 16px;
   font-weight: 300;
   color: #959292;
   margin-bottom: 0px;
 }
 .explore-button-div.flex-inline-button{
   display: flex;
   gap: 15px;
   align-items: center;
   margin-top: 40px;
 }
 .explore-button-div.flex-inline-button .explore-more-featire{
   width: 100%;
   padding: 8px 20px;
 }
 .partners-section{
   padding: 100px 0;
 }
 
 .partners-section h3{
   text-align: center;
   font-size: 40px;
   margin-bottom: 40px;
 }
 
 .partners-flex {
   display: flex;
   align-items: center;
   gap: 30px;
   justify-content: center;
 }
 .partners-flex-item {
   max-width: 220px;
   padding: 20px;
   background-color: #fff;
   border-radius: 16px;
   box-shadow: 0px 1px 13px rgb(0 0 0 / 7%);
 }
 .card-partnr-review {
   background-color: #1e1f23;
   padding: 30px;
   border-radius: 20px;
   margin: 0 10px;
   box-shadow: 0px 2px 10px #191b2517;
 }
 
 .card-partnr-review .logo-partner{
  width: 50px;
 }
 
 .card-partnr-review p{
   font-size: 14px;
   color: #fff;
   font-weight: 300;
 }
 .flex-review {
   display: flex;
   align-items: center;
   gap: 10px;
 }
 .flex-review img{
   width: 40px;
   height: 40px;
   object-fit: cover;
   border-radius: 50%;
 }
 .flex-review h6{
   margin-bottom: 2px;
 }
 .flex-review p{
   margin-bottom: 0;
 }
 .partners-reviews-section {
   background-color: #000;
   color: #fff;
   padding: 100px 0;
 }
 
 .slider-slick-settings {
   /* overflow: hidden; */
   position: relative;
 }
 
 
 .slider-slick-settings .slick-slide{
   margin-right:15px;
     margin-left:15px;
 }
 .slider-slick-settings .slick-slider {
   margin: 0px -15px 30px;
 }
 
 
 .slider-slick-settings .slick-next, .slider-slick-settings .slick-prev {
   border: none;
   cursor: pointer;
   display: block;
   font-size: 0;
   height: 20px;
   line-height: 0;
   padding: 0;
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   width: 20px;
 }
 .slider-slick-settings .slick-prev {
   left: -25px;
 }
 .slider-slick-settings .slick-next {
   right: -25px;
 }
 
 
 .slider-slick-settings .slick-next:before, .slider-slick-settings .slick-prev:before {
  display: none;
 }
 .slider-slick-settings .slick-prev{
   background: #000 url('./assets/images/imgs/chevron-left.svg') no-repeat center;
   width: 40px !important;
     height: 40px !important;
     border-radius: 50px;
     padding: 0; z-index: 1;
 }
 .slider-slick-settings .slick-next{
   background: #000 url('./assets/images/imgs/chevron-right.svg') no-repeat center;
   width: 40px !important;
     height: 40px !important;
     border-radius: 50px;
     z-index: 1;
     padding: 0;
 }
 .slider-slick-settings .slick-arrow{
   display: flex !important;
 
 }
 
 .slick-list, .slick-slider, .slick-track {
   position: relative;
 }
 .slider-slick-settings .slick-slider {
   -webkit-touch-callout: none;
   touch-action: pan-y;
   -webkit-user-select: none;
   user-select: none;
   -khtml-user-select: none;
   margin: 0px auto 0px;
 }
 
 .slider-slick-settings .slick-list {
   margin: 0;
   overflow: hidden;
   padding: 0px 0 20px;
 }
 
 
 .footer-section-home {
   background-color: #171717;
   padding: 100px 0 0;
   color: #fff;
 }
 
 .getintouch-section p{
   color: #808393;
   font-weight: 300;
   max-width: 520px;
   margin-bottom: 50px;
 }
 
 .flex-footer-icon {
   display: flex;
   gap: 15px;
   align-items: center;
   margin-bottom: 25px;
 }
 .flex-footer-icon .iconfooter {
   min-width: 30px;
 }
 .flex-footer-icon .footericon-right h6{
 margin-bottom: 2px;
 }
 .flex-footer-icon .footericon-right p{
   margin-bottom: 0;
 }
 
 
 
 .getintouch-form{
 margin-bottom: 50px;
 }
 .form-footer-input{
   margin-bottom: 25px;
   position: relative;
 }
 .form-footer-input label{
   font-size: 12px;
   color: #ccc;
   font-weight: 300;
   display: block;
 }
 .form-footer-input input{
   width: 100%;
   display: block;
   height: 48px;
   padding: 6px 15px;
   background-color: #26282c;
   color: #fff;
   font-size: 14px;
   border: none;
 }
 .getintouch-btn{
   text-align: center;
 }
 .btn-touch{
   display: inline-block;
   width: 200px;
   padding: 10px 30px;
   text-align: center;
   border-radius: 6px;
   border: 1px solid #0F74E0;
   background-color: #0F74E0;
   color: #fff;
   font-size: 14px;
 }
 
 
 .footer-about-company .f-logo{
 margin-bottom: 30px;
 }
 
 .footer-about-company p{
   color: #808393;
   font-weight: 300;
   max-width: 520px;
 }
 
 .footer-about-company .sociallogo-c{
   margin-left: 0;
   padding-left: 0;
   display: flex;
   align-items: center;
   gap: 20px;
   margin-top: 30px;
 }
 
 .footer-about-company .sociallogo-c a img{
     width: 24px;
 }
 
 .quick-links h3{
   font-size: 18px;
   font-weight: 600;
 }
 .quick-links ul{
   margin-left: 0;
   padding-left: 0;
 }
 .quick-links ul li{
   display: block;
   margin-bottom: 10px;
 }
 .quick-links ul li a{
   color: #6a7076;
   display: inline-block;
 }
 
 .quick-links ul li a img {
   width: 20px;
 }
 
 .footer-all-rights {
   display: flex;
   align-items: center;
   justify-content: center;
   border-top: 1px solid #27282e;
   padding: 10px 0;
   margin-top: 50px;
 }
 .left-text-all-rights{
   font-size: 12px;
   color: #808393;
   font-weight: 300;
 }
 .right-text-all-rights ul{
   display: flex;
   align-items: center;
   gap: 30px;
 }
 .right-text-all-rights ul li a{
   font-size: 12px;
   color: #808393;
   font-weight: 300;
 }
 
 .globalsecton{
  background-color: #171717;
  padding: 60px 0;
  text-align: center;
 }
 .globalsecton img{
  height: 490px;
 }
 .globeimagesection{
  max-width: 500px;
  margin: 0 auto;
 }
 .globalsecton h3{
  font-size: 50px;
    text-align: center;
    font-weight: 600;
    color: #ebc593;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 70px;
 }
 .globalsecton h3 img{
  width: 70px;
    height: auto;
   }
 
 
 .l1X-stakes-modal-footer {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 20px 16px;
  justify-content: center;
}
.trans-pagination ul{
  display: flex;
  align-items: center;
  gap: 8px;
}
.trans-pagination ul li{
  font-size: 14px;
  cursor: pointer;
}
.trans-pagination ul li .btn{
  min-width: 32px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  border-color: #d9d6d6;
  padding: 0 6px;
}
.trans-pagination ul li.btn.btn-active{
  background: #ceb891;
          border-color: #ceb891;
          color: #000;
}
.trans-pagination ul li.remaining-page{
  color: #a4aabe;
} 
.trans-pagination ul li svg{
  width: 16px;
  height: 16px;
}
.sizesmicon{
  width: 20px;
}





.whitepaper-btn{
  display: flex;
  gap: 20px;
  margin-top: 30px;
}
.explore-more-featire.buynow-btn{
border: 1px solid #fff;
background-color: transparent;
color: #fff;
display: flex;
align-items: center;
gap: 10px;
}

.explore-more-featire.buynow-btn svg{
  width: 20px;
}

.tranfer-section{
  background-color: #000000;
  color: #fff;
  padding: 60px 0;
}


.card-div-list h3{margin-bottom: 20px;margin-bottom: 20px;    color: #eec48f;}
.card-div-list ul{
  padding-left: 0px;
  max-width: 621px;
}
.card-div-list ul li:hover{
  border: 1px solid #eec48f;
  background-color: #342a1e;
  color: #fff;
}
.card-div-list ul li{
  display: block;
  transition: 0.5s;
  padding: 10px 10px;
  border: 1px solid #3b3b40;
  margin-bottom: 10px;
  border-radius: 9px;
  background-color: #171719;
  font-size: 13px;
  color: #a0a0b5;
}
.card-div-list ul li span{
  font-weight: 600;
  color: #fff;margin-right: 5px;
}


.buysend-section{
  padding: 60px 0;
  background-color: #171717;
  color: #fff;
}
.image-width-300{
  max-width: 450px;
}




.and-button-white{
  position: relative;
}

.whitebtn{
  position: absolute;
  background-color: #ee5b1e;
  padding: 10px 20px;
  border-radius: 36px;
  border: 1px solid #ee5b1e;
  color: #ffffff;
  left: 20px;
  right: 0px;
  bottom: 20px;
  display: block;
  width: 330px;
  text-align: center;
  font-weight: 600;
  transition: 0.5s;
  cursor: pointer;
}
.whitebtn:hover{
  background-color: #477778;
  border: 1px solid #477778;
  color: #f9d694;
}





.title-section-home.text-token-info p{
  color: #ebc593;
}
.linkh{
  color: #429aff;
  display: inline-block;
}


.buynow-tokens{
  position: relative;
}

.buynow-tokens .link-buy-and{
  position: absolute;
  padding: 10px 30px;
  width: 297px;
  text-align: center;
  background-color: #3b81f5;
  border-radius: 30px;
  border: 1px solid #3b81f5;
  color: #fff;
  left: 0;
  right: 86px;
  margin: 0 auto;
  bottom: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 500;
}
.buynow-tokens .link-buy-and:hover{
  background-color: #477778;
  border: 1px solid #477778;
  color: #f9d694;
}
.buynow-tokens .link-buy-and img{
  width: 20px;
}











