.skeleton-loader {
    height: 6px;
    min-width: 60px;
    display: block;
    /* background: linear-gradient(90deg, #cccccc 25%, #cccccc 50%, #f7f7f7 75%); */
    background: linear-gradient(90deg, #f7f7f7 25%, #eeeeee 50%, #f7f7f7 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 2s infinite ease-in-out;
    border-radius: 8px;
  }
  @keyframes skeleton-loading {
    to {
      background-position-x: -200%;
    }
  }
  .darktheme .skeleton-loader {
    height: 6px;
    min-width: 60px;
    display: block;
    background: linear-gradient(90deg, #181c24 25%, #282d37 50%, #181c24 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 2s infinite ease-in-out;
    border-radius: 8px;
  }

  