.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-container {
  background: #1d2129;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 400px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  text-align: left;

  /* padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer; */
}

.close-button:hover {
  background-color: '#212837' !important;
}

.popup-text {
  font-size: 16px;
  font-weight: bold;
  /* color: #333; */
  color: #eec48f;
  margin-bottom: 40px;
  margin-top: 40px;
}

.wallet-options {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.wallet-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #212837;
  background-color: #f5f5f5;
  transition: background 0.3s ease;
  width: 100%;
}

.wallet-btn img {
  width: 24px;
  height: 24px;
}

.wallet-btn:hover {
  background-color: #e0e0e0;
}