.spinner {
    border: 4px solid #00000036;
    border-radius: 50%;
    border-top: 4px solid #000;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  